
.boxSlide{
	-ms-overflow-style: none;
	display: block;
	width: 100%;
	height: 100%;
	overflow-x: auto;
	overflow-y: hidden;
	flex: 1 1 auto;
	white-space: nowrap;



	&::-webkit-scrollbar{
		display: none;
	}
	&>label{
		display: inline-block;
		width: auto;
		height: 100%;
	}
	ul {
		display: inline-block;
		width: auto;
		height: 100%;
		color: rgba(0, 0, 0, 0.72);
 	}
}
.optionBar {
    width: calc( 100% - .4rem);
    margin: 0 .2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .box {
        width: 50%;
        display: inline-block;
        :first-child {
            margin-right: 0.5rem;
        }
    }
    .rmdp-container {
        width: calc(100% - .5rem);
        max-width: 100dvw;
      
        .rmdp-input{
          width: 100%;
          max-width: 100dvw;
          margin: 0.3rem;
          padding: 5px 10px;
          border: 1px solid #ccc;
          border-radius: 0.2rem;
          height: 40px;
          font-size: 1.2rem;
          text-align: center;
        }
        
        .rmdp-calendar {
         
        }  
      
      }
}



// i>svg {
//     display: block;
//     width: 100%;
//     height: 100%;
// }

// input[type="text"] {
//     transform: translate3d(0, 0, 0);
// }

// input[type="button"] {
//     cursor: pointer;
// }

.login {
    background: #f5f5f5;
}

.box_login {
    width: 100%;
    max-width: 320px;
    margin: 16px auto;
    padding: 24px 16px 16px 16px;
    text-align: center;
    background: #fff;
    box-shadow: 0 -1px 20px 0 rgba(0, 0, 0, 0.24);
}


input.userid {
    font-family: TAHOMA2, "Times New Roman", Georgia;
}


.text_error {
    display: block;
    width: 100%;
    line-height: 16px;
    margin-bottom: 16px;
    text-align: center;
    font-size: 12px;
    color: #ce3636;
}

.btn_login {
    width: 100%;
    height: 48px;
    line-height: 48px;
    margin-bottom: 16px;
    padding: 0 16px;
    border-radius: 4px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    border: none;
    color: #fff;
    background: #19805c;
    transition: background 0.3s ease;
}

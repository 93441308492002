.checkboxFilter {
    user-select: none;
    position: relative;
    display: inline-block;
    max-width: 100dvw;
    min-height:60px;
    width: calc(100% - 1rem);
    margin: 0.3rem;
    overflow: hidden auto;
    .anchor {
        position: relative;
        cursor: pointer;
        display: inline-block;
        max-width: 100dvw;
        width: 100%;
        line-height: 1.8rem;
        font-size: 1.2rem;
        margin: 0.3rem;
        padding: 5px 25px 5px 10px;
        border: 1px solid #ccc;
        border-radius: 0.2rem;
        height: 40px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:after {
            position: absolute;
            content: "";
            border-left: 2px solid black;
            border-top: 2px solid black;
            padding: 5px;
            right: 10px;
            top: 20%;
            transform: rotate(-135deg);
        }

        &:active:after {
            right: 9px;
            top: 21%;
        }
    }
    .noItems {
        padding: 1rem 0.3rem;
        text-align: center;
        font-size: 1.5rem;
        color: #d25d5d;
    }
    ul.items {
        position: relative;
        width: 100%;
        padding: 2px;
        margin: 0 0.3rem;
        border-top: none;
        background: #fff;
        z-index: 1;

        li {
            list-style: none;
            line-height: 2rem;
            font-size: 1.2rem;
            display: block;
            min-width: 50%;
            label {
                display: block;
                cursor: pointer;
                &:hover {
                    background: #f0f0f0;
                }
            }
            input {
                all: revert;
                margin-right: 0.5rem;
                width: 1.2rem;
                height: 1.2rem;
                line-height: 2rem;
            }
        }
    }

    &.visible {
        .anchor {
            color: #0094ff;
        }
    }
}
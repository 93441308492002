$defaultColor: #000;
$defaultBackground: #fff;
$border: 1px solid grey;

.inputNumber {
    position: relative;
    display: flex;
    justify-content: space-between;
    border: $border;
    border-radius: 3px;
    padding: .1rem .2rem;
    margin: .1rem;
    min-width: 72px;
    width: calc( 80% - .3rem);
    box-shadow: 1px 1px 1px #ccc6;
    background: $defaultBackground;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    input[type="number"] {
        -moz-appearance: textfield;
        width: 100%;
        border: $border;
        text-align: right;
        color:$defaultColor;
        padding: .1rem .2rem;
    }

    i {
        cursor: pointer;
        padding: .1rem;
        margin: .1rem;
        color:$defaultColor;
    }
}
/* POPUP Center*/
.popup_center {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	padding: 16px;
	-webkit-transform: translate3d(0, 100%, 0);
	transform: translate3d(0, 100%, 0);
	transition: all 0.3s ease;
	z-index: 2;
	visibility: collapse;

	&.on {
		-webkit-transform: translate3d(0, 0%, 0);
		transform: translate3d(0, 0%, 0);
		visibility: visible;
		background: #000C;
	}

	> div {
		display: flex;
		width: 100%;
		max-width: 640px;
		min-width: 420px;
		max-height: 100%;
		flex-direction: column;
		background: #fff;
		z-index: 1;
		border-radius: 4px;
		overflow: hidden;

		.popup_title {
			position: relative;
			display: flex;
			width: 100%;
			height: 48px;
			line-height: 16px;
			padding: 4px 0;
			flex: none;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			font-weight: bold;
			text-transform: uppercase;
			color: #000;
			background: #ededed;
		}

		.popup_content {
			margin: 0;
			padding: 16px 16px 0 16px;
			overflow-y: auto;
		}

		.popup_btnbox {
			padding: 16px;
		}
	}
}

/* POPUP kick */
.popup_kick > div {
	min-width: 288px;
	overflow: auto;
	max-height: 95dvh;
	box-shadow: 0px 0px 4px #000000AA;
	&::-webkit-scrollbar {
		display: none;
	  }
}

// @media screen and (max-height: 600px) {
// 	.popup_center > div {
// 		min-height: 75dvh;
// 	}
// }
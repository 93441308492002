.labInput {
    position: relative;
    display: block;
    width: 100%;
    height: 48px;
    margin-bottom: 16px;

    > input {
        display: block;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0 32px 0 16px;
        font-size: 16px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.32);
        color: #000;
        box-shadow: none;
        transition: all 0.3s ease;
        pointer-events: auto;
        
        /*focus*/
        &:focus {
            border: 2px solid #19805c;
            ~ .textInput:after {
                top: -8px;
                height: 16px;
                font-size: 12px;
                color: #19805c !important;
                background: #fff;
            }
        }
        /*validation*/
        &:valid ~ .textInput:after {
            top: -8px;
            height: 16px;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.56);
            background: #fff;
        }
    }

    .textInput {
        line-height: normal !important;
        pointer-events: none;
        &:after {
            content: attr(data-tooltip);
            position: absolute;
            top: 0px;
            left: 14px;
            display: flex;
            height: 100%;
            align-items: center;
            padding: 0 4px;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.24);
            transition: all 0.3s ease, background 0.1s 0.1s;
        }
    }

    &.error > input,
    &.error.on > input {
        border: 2px solid #e76565 !important;

        &:valid ~ .textInput:after,
        &.on .textInput:after {
            color: #e76565 !important;
        }
    }

    &.error.on > input {
        &.on .textInput:after {
            top: -8px;
            font-size: 12px;
            color: #e76565;
        }
    }

    &.on > input:valid ~ .btnClear {
        z-index: 1;
        cursor: pointer;
    }

    &.on .iconStatus {
        visibility: hidden;
    }

    /*清除按鈕*/
    & .btnClear {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 32px;
        height: 32px;
        padding: 8px;
        background-repeat: no-repeat;
        background-position: center;
        transition: none !important;
        pointer-events: auto;
        z-index: -1;
        /* Ⓧ */
        svg {
            width: 100%;
            height: 100%;
            opacity: 0;
            fill: rgba(0, 0, 0, 0.4);
            pointer-events: none;
        }
    }

    & .textError {
        display: block;
        width: 100%;
        line-height: 16px;
        margin-bottom: 16px;
        text-align: center;
        font-size: 12px;
        color: #ce3636;
    }

    & .btnDelet {
        display: none;
    }

    /* PC hover effect */
    @media (pointer: fine) and (max-resolution: 2dppx),
    screen and (pointer: fine) and (-webkit-max-device-pixel-ratio: 2),
    (-moz-touch-enabled: 0),
    screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
        > input:hover {
            border-color: #33997a;
            ~ .textInput:after {
                color: #33997a;
            }
        }
    }
}

.title_help {
    display: flex;
    width: 100%;
    height: 48px;
    align-items: center;
    flex: none;
    background-position: bottom;
    background-color: #e1dbd8;
}

.title_help .btn_back {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin: 8px;
    flex: 0 0 32px;
    transition: background 0.3s ease;
}

.title_help .btn_back i {
    display: block;
    width: 100%;
    height: 100%;
    padding: 8px;
}

.title_help span {
    display: inline-block;
    width: calc(100% - 96px);
    height: 24px;
    line-height: 24px;
    padding: 0 4px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    flex: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
}

.btn_chgpwd {
    display: flex;
    width: 100%;
    margin-top: 16px;
    align-items: center;
    justify-content: space-between;
}

.btn_chgpwd>div {
    display: inline-block;
    width: 50%;
    height: 48px;
    line-height: 48px;
    text-align: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    flex: 1 1 auto;
}

.btn_chgpwd>div~div {
    margin-left: 16px;
}

.content_acchelp{
	width: 100%;
	will-change: opacity;
}
.content_acchelp .help_odds_top, .content_acchelp .help_odds_bottom {
	width: 100%;
	padding: 0 16px;
}
.noteBox {
    margin-bottom: 24px;
   .noteBox2 {
        display: block;
        width: 100%;
        line-height: 20px;
        color: #000;
        font-size: 14px;

        li {
            list-style-type: decimal;
            margin-left: 1rem;
            margin-bottom: 1rem;
            padding-left: 1rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
.changePassword {
    margin: 0 auto;
    padding: 0 16px 16px 16px;
    @media screen and (min-width: 1024px) {
        width: 100%;
        max-width: 650px;
        padding: 0 0 16px 0;
    }
}

.buttonToTopic {    
    cursor: pointer;
    background: #CCCA;
    border: solid 2px #AAA;
    box-shadow: 0 0 1px #AAA;
    border-radius: 0.5rem;
    padding: 0.3rem;
    font-size: 2rem;
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: none;
    z-index: 9;
    // -webkit-animation: upper 2s infinite;
    &.showButton {
        display: block;
    }
}

@keyframes upper {
    0%{transform:translateY(-3px);}
    30%{transform:translateY(3px);}
    100%{transform:translateY(-3px);}
}

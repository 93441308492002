@import '../../style/boxSlide.scss';

.box_header {
	position: fixed;
	top: 0;
	display: flex;
	width: 100%;
	height: 56px;
	justify-content: space-between;
	z-index: 2;
	background: #503f32;
	padding-right: 42px;
	.btn_home {
		display: inline-block;
		width: 48px;
		height: 100%;
		padding: 16px 12px;
		flex: 0 0 48px;
		text-align: center;
		cursor: pointer;
		transition: background 0.3s ease;

		svg {
			width: 24px;
			height: 24px;
			fill: rgba(255, 255, 255, 0.64);
		}

		&.off {
			pointer-events: none;
			svg {
				fill: rgba(255, 255, 255, 0.4);
			}
		}
	
		&.on svg {
			fill: #debb69;
		}

	}

	.btn_acc {
		position: relative;
		display: inline-block;
		width: 56px;
		height: 56px;
		flex: 0 0 56px;
		background-repeat: no-repeat;
		background-position: center;
		transition: background 0.3s ease;

		.dot_green,
		.dot_red {
			width: 4px;
			height: 4px;
			border-radius: 50%;
			position: absolute;
			top: 16px;
			right: 12px;
			display: none;
			display: block;

		}
		.dot_green {
			background: #3db893;
		}

		.dot_red {
			background: #e53c50;
		}
	}

	.num_betslip.on {
		display: flex;
	}

	.shadow_l:before {
		left: 48px;
		background-image: linear-gradient(to left, rgba(72, 57, 45, 0), #48392d);
	}

	.shadow_r:after {
		right: 56px;
		background-image: linear-gradient(to right, rgba(72, 57, 45, 0), #48392d);
	}

	.btn_header {
		position: relative;
		display: inline-block;
		height: 100%;
		line-height: 56px;
		padding: 0 12px;
		vertical-align: top;
		flex: 0 0 auto;
		color: rgba(255, 255, 255, 0.64);
		cursor: pointer;
		transition: color 0.3s ease;
		&.badge[data-badge]:not([data-badge='']) { 
            // 有資料的話 再填入 :: after 顯示style 
            &::after { 
                content: attr(data-badge); 
                position: absolute; 
                top: 8px; 
                right: -2px; 
                width: 16px; 
                height: 16px; 
                border-radius: 50%; 
                font-size: 12px; 
                line-height: 16px; 
                text-align: center; 
                font-weight: 400; 
                transition: background 0.3s ease; 
                background: #e22525; 
                color: #f3dfd0; 
            } 
        }

		&:hover {
			background: #aaaa;
		}

		&.on {
			color: #debb69;
		}

		&.off {
			color: rgba(255, 255, 255, 0.24);
			pointer-events: none;
		}

		&.mygame_off {
			color: rgba(255, 255, 255, 0.24);
		}

		&.on_ani,
		&.off_ani {
			color: #fff;
		}

		.num_betslip {
			top: 8px;
			left: calc(100% - 12px);
			margin-left: 2px;
		}

		.text_new {
			position: absolute;
			top: 2.5px;
			left: 12px;
			display: block;
			line-height: 16px;
			font-size: 12px;
			font-weight: 400;
			color: #ff6767;
		}

		&.off .text_new,
		&.mygame_off .text_new {
			color: rgba(255, 103, 103, 0.375);
		}

		.dot_green {
			position: absolute;
			top: -4px;
			right: 8px;
			transition: top 0.3s cubic-bezier(0.68, -0.55, 0.265, 1);
		}

		.dot_green.on {
			top: 16px;
		}

		.ani_mygame {
			position: absolute;
			top: calc(50% - 24px);
			left: calc(50% - 24px);
			width: 48px;
			height: 48px;
			opacity: 0;
		}

		&.on_ani .on_wave,
		&.on_ani .on_wave2,
		&.off_ani .off_wave {
			opacity: 1;
		}

		.on_ani .on_wave {
			animation: circle_ani 500ms ease both;
		}

		.on_ani .on_wave2 {
			animation: circle_ani 800ms 200ms ease both;
		}

		.off_ani .off_wave {
			animation: circle_ani 500ms ease both;
		}

		span {
			display: inline-block;
			vertical-align: middle;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}

	.btn_special {
		max-width: 120px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		text-transform: uppercase;
	}

	/* PC hover effect */
	@media (pointer: fine) and (max-resolution: 2dppx),
		screen and (pointer: fine) and (-webkit-max-device-pixel-ratio: 2),
		(-moz-touch-enabled: 0),
		screen and (-ms-high-contrast: active),
		(-ms-high-contrast: none) {

		.btn_home:hover,
		.btn_acc:hover {
			background-color: #5d4e42;
		}

		.btn_header:not(.mygame_off):hover {
			color: #debb69;
		}

		.btn_header:not(.mygame_off):hover .icon_SP {
			// background: url('./images/icon_sp_go.svg') no-repeat center center;
		}
	}

	/* All active effect */
	.btn_home:active,
	.btn_acc:active {
		background-color: #3c2f26;
	}

	.btn_header:not(.mygame_off):active {
		color: #debb69;
		background: #3c2f26;
	}

	.btn_header:not(.mygame_off):active .icon_SP {
		// background: url('./images/icon_sp_go.svg') no-repeat center center;
	}

	.nav_header {
		display: none;
		align-items: center;
		justify-content: flex-end;
		flex: none;
	}

	.time_header {
		margin: 0 20px 0 14px;
		font-size: 14px;
		color: rgba(255, 255, 255, 0.4);
	}

	.money_header {
		height: 100%;
		line-height: 56px;
		margin: 0 8px 0 16px;
		font-size: 14px;
		color: #debb69;
		cursor: pointer;
	}

	@media screen and (min-width: 1024px) {
		.nav_header {
			display: flex;
		}
	}

	@media screen and (max-width: 1279px) {
		.time_header {
			display: none;
		}
	}

	i.nook {
		font-family: 'sans-serif', 'system-ui', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
		width: 105px;
		height: 0;
		border-left: 26px solid transparent;
		border-right: 26px solid transparent;
		transform: rotate(45deg);
		position: absolute;
		right: -25px;
		top: 10px;
		color: #ffc;
		text-align: center;
		font-size: 13px;
		font-weight: bold;
		line-height: 2rem;
		z-index: 3;
		user-select:none;
		-webkit-user-select:none;
		-moz-user-select:none;
		&.open {
			border-bottom: 26px solid rgb(75, 197, 157);
		}

		&.close {
			border-bottom: 26px solid rgb(221, 62, 34);
		}
	}
}

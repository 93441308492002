.buttonRefresh {
    width: 3rem;
    height: 2rem;
    margin: .2rem;
    padding: .2rem 0 .2rem .4rem;
    overflow: hidden;
    
    color: #000;
    font-size: 1rem;
    line-height: 1rem;
    cursor: pointer;

    background: #f2f2f2;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    box-shadow: 0 0 2px #000000CC;
    display: inline-block;
    position: relative;

    .sesc {
        all: revert;
        min-width: 1.5rem;
        color: #000;
    }

    i.fleft {
        float: left;
    }
    i.rotate {
      animation: rotate-taichi 2s infinite linear;
    }
}


@keyframes rotate-taichi {
    0%{transform:rotate(0deg);}
    100%{transform:rotate(720deg);}
}